<template>
    <p-feature-flag ref="AccountingFlag" name="Accounting">
    <SearchScreen
        title="Stock Adjustment Reasons"
        api-destination="stockadjustmentreason"
        edit-key="stockAdjustmentReasonId"
        :filters="filters"
        :fields="fields"
        :filterBy="filterBy"
        :show-set-to="false"
        :use-is-active-checkbox="false"
        add-new-button
    >   
           
        <template v-slot:cell(glCode)="{  }">
            <span>Unassigned</span>
        </template>
    </SearchScreen>
</p-feature-flag>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';
export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'StockAdjustmentReasonFilter',
            filters: {
                automatic: '',
                perPage: 100,
                sortBy: 'reason',
                sortDesc: true,
            },
            filterBy: ['All', 'Adjustment Reason', 'Reason Description', 'GL Code'],
            fields: [
                {
                    key: 'reason',
                    sortable: true,
                    label: 'Adjustment Reason'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Reason Description'
                },
                {
                    key: 'glCode',
                    sortable: true,
                    label: 'GL Code'
                },
                {
                    key: 'isActive',
                    label: 'Status',
                    formatter: value => {
                        return value ? 'Active' : 'Inactive';
                    },
                    sortable: true
                }
            ]
        };
    },
    methods: {}
};
</script>