<template>
    <SearchScreen
        title="Chart of Accounts"
        ref="searchScreen"
        api-destination="chartofaccounts"
        edit-key="chartOfAccountsId" 
        :filters="filters"
        :fields="fields"
        :filter-by="filterBy"
        add-new-button
    >
        <template v-slot:belowSearch>
            <b-row class="ml-2 mr-1 mt-2">
                <b-col cols="12" sm="4" xl="2">
                    <p-select
                            label="Location"
                            data-type="locations"
                            v-model="filters.locationFilter"
                            addEmptyOption
                        />
                </b-col>
                <b-col cols="12" sm="4" xl="2">
                    <p-select
                            label="Description"
                            data-type="chartOfAccountsDescriptions"
                            v-model="filters.descriptionFilter"
                            addEmptyOption
                        />
                </b-col>
                <b-col cols="12" sm="4" xl="2">
                    <p-select
                        label="Category"
                        data-type="chartOfAccountsCategories"
                        v-model="filters.categoryFilter"
                        addEmptyOption
                    />
                </b-col>
                <b-col cols="12" sm="4" xl="2">
                    <p-select
                            label="Type"
                            data-type="chartOfAccountsTypes"
                            v-model="filters.accountTypeFilter"
                            addEmptyOption
                        />
                </b-col>
            </b-row>
        </template>
    </SearchScreen>
</template>

<script>
import SearchScreen from '../../components/SearchScreen.vue';
import SearchStorage from '@/components/mixins/SearchFilterStorage.js';

export default {
    mixins: [SearchStorage],
    components: { SearchScreen },
    data() {
        return {
            isBusy: false,
            filterName: 'ChartOfAccountsFilter',
            filters: {
                automatic: null,
                perPage: 100,
                sortBy: 'ledgerNumber',
                descriptionFilter: null,
                categoryFilter: null,
                accountTypeFilter: null
            },
            filterBy: [
                'All',
                'LedgerNumber',
                'Reference'
            ],
            fields: [
                {
                    key: 'location',
                    sortable: true,
                    label: 'Location'
                },
                {
                    key: 'ledgerNumber',
                    sortable: true,
                    label: 'Ledger Number'
                },
                {
                    key: 'description',
                    sortable: true,
                    label: 'Description'
                },
                {
                    key: 'category',
                    sortable: true,
                    label: 'Category'
                },
                {
                    key: 'accountType',
                    sortable: true,
                    label: 'Account Type'
                },
                {
                    key: 'reference',
                    sortable: true,
                    label: 'Reference'
                },
                {
                    key: 'chartOfAccountsId',
                    hidden: true
                }
            ]
        };
    }
}
</script>