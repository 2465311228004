<template>
    <p-form @submit="onSubmit">
        <div class="container-fluid content-wrapper" v-if="ledgerAccount">
            <b-row class="content-heading">
                <b-col>
                    <div>{{ isNew ? 'Add' : 'Edit' }} Ledger Account</div>
                </b-col>
                <b-col class="text-right">
                    <p-button type="submit" :is-busy="isBusy" variant="primary"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
            <p-card>
                <b-row cols="1" cols-lg="2">
                    <b-col>
                        <p-select
                            label="Location"
                            v-model="ledgerAccount.locationId"
                            data-type="locations"
                            rules="required"
                            @selectedObject="(option) => ledgerAccount.locationName = option.text"
                        />
                        <p-input
                            label="Ledger Number"
                            v-model="ledgerAccount.ledgerNumber"
                            rules="required|max:255"
                        />
                        <p-select
                            label="Description"
                            v-model="ledgerAccount.descriptionId"
                            data-type="chartOfAccountsDescriptions"
                            rules="required"
                        />
                    </b-col>
                    <b-col>
                        <span class="d-inline-block">
                            <p-select
                                class="d-inline-block"
                                label="Category"
                                v-model="ledgerAccount.categoryId"
                                data-type="chartOfAccountsCategories"
                                rules="required"
                                @filter="(event) => ledgerAccount.accountType = event"
                            />
                            <p class="d-inline-block ml-3 body-large">{{ this.ledgerAccount.accountType }}</p>
                        </span>
                        <p-input
                            label="Reference"
                            v-model="ledgerAccount.reference"
                            rules="max:255"
                        />
                    </b-col>
                </b-row>
            </p-card>
            <b-row class="mt-4">
                <b-col class="text-center mb-3">
                    <p-button
                        variant="primary"
                        type="submit"
                        :is-busy="isBusy"
                        class="text-right"
                        >Save</p-button
                    >
                </b-col>
            </b-row>
        </div>
    </p-form>
</template>

<script>
import axios from "axios";
import NavigationGuard from '@/components/mixins/NavigationGuard.js'
import editPage from '@/components/mixins/EditPage';

export default {
    mixins: [NavigationGuard, editPage],
    data() {
        return {
            ledgerAccount: null,
            isBusy: false
        };
    },
    watch: {
        ledgerAccount: NavigationGuard.$watcher,
        $route: function(to, from) {
            if (to !== from) {
                this.loadData();
            }
        }
    },
    computed: {
        isNew() {
            return this.id === 'new';
        }
    },
    methods: {
        onSubmit() {
            this.isBusy = true;
            axios.post("chartOfAccounts", this.ledgerAccount)
                .then((response) => {
                    this.dataSaved();
                    if (this.isNew) {
                        this.$router.push("/chartOfAccounts/" + response.data);
                    }
                    this.$toasted.global.app_success(`Ledger Account '${this.ledgerAccount.ledgerNumber}' saved successfully.`).goAway(5000);
                })
                .finally(() => {
                    this.isBusy = false;
                });
        },
        loadData() {
            let promise = this.isNew
                ? axios.get('chartofaccounts/new')
                : axios.get('chartofaccounts', { params: { id: this.id } });

            promise.then(resp => {
                this.ledgerAccount = resp.data;
                if (!this.isNew) {
                    this.dataLoaded();
                }
            });
        }
    },
    mounted: function() {
        this.loadData();
    }
};
</script>

<style scoped lang="scss">
/deep/ .body-large {
    font-size: 1rem;
}
</style>